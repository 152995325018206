import React, { memo } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Caption, Button, Subheading, TouchableRipple, useTheme } from 'react-native-paper';
import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
import {
  PERSONAL_SITE,
  CHRISTIAN_MEMIJE,
  CREATED_BY,
  REQUEST_IT,
  CREDITS,
  PRIVACY_POLICY,
  PRIVACY_POLICY_URL,
  CREDITS_URL,
  COMMENTS_SUGGESTIONS,
  INSTAGRAM,
  MISSING_ADLIB,
  GET_ON_GOOGLE_STORE,
  GET_ON_APPLE_STORE,
  GOOGLE_STORE_LINK,
  APPLE_STORE_LINK,
} from '../utils/strings';
import { UNIT_MULTIPLE } from '../utils/theme';
import { VERSION, TITLE, CONTACT_FORM_URL, REQUEST_FORM_URL, IG_URL } from '../utils/constants';

function openLinkInNewTab(url: string): void {
  if (Platform.OS === 'web' && window) {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }
}

const subheadingFontSize: number = 16;
const captionFontSize: number = 12;

export const Footer = memo(FooterComponent);

function FooterComponent() {
  const { colors } = useTheme();

  return (
    <View style={styles.container}>
      <View style={[styles.inlineTextAndButtons, { paddingBottom: UNIT_MULTIPLE / 2 }]}>
        <Subheading style={styles.subHeading}>{MISSING_ADLIB} </Subheading>
        <Button
          onPress={() => openLinkInNewTab(REQUEST_FORM_URL)}
          mode="text"
          compact={true}
          labelStyle={[styles.linkLikeButton, styles.subHeadingButton]}
        >
          {REQUEST_IT}
        </Button>
      </View>

      {/* {Platform.OS === 'web' && (
        <View style={styles.badgesWrapper}>
          <TouchableRipple onPress={() => openLinkInNewTab(GOOGLE_STORE_LINK)}>
            <Image
              source={require('../../assets/android_badge.png')}
              accessibilityLabel={GET_ON_GOOGLE_STORE}
              style={[styles.badgeImage]}
            />
          </TouchableRipple>
          <View style={styles.badgesSpacer}></View>
          <TouchableRipple onPress={() => openLinkInNewTab(APPLE_STORE_LINK)}>
            <Image
              source={require('../../assets/ios_badge.png')}
              accessibilityLabel={GET_ON_APPLE_STORE}
              style={[styles.badgeImage]}
            />
          </TouchableRipple>
        </View>
      )} */}

      <View style={[styles.inlineTextAndButtons, { paddingTop: UNIT_MULTIPLE / 2 }]}>
        <Button
          onPress={() => openLinkInNewTab(CONTACT_FORM_URL)}
          mode="text"
          compact={true}
          labelStyle={[styles.linkLikeButton, styles.captionButton]}
        >
          {COMMENTS_SUGGESTIONS}
        </Button>
        <Caption style={styles.caption}>{' | '}</Caption>
        <Button
          onPress={() => openLinkInNewTab(IG_URL)}
          mode="text"
          compact={true}
          labelStyle={[styles.linkLikeButton, styles.captionButton]}
        >
          {INSTAGRAM}
        </Button>
        <Caption style={styles.caption}>{' | '}</Caption>
        <Button
          onPress={() => openLinkInNewTab(CREDITS_URL)}
          mode="text"
          compact={true}
          labelStyle={[styles.linkLikeButton, styles.captionButton]}
        >
          {CREDITS}
        </Button>

        <Caption style={styles.caption}>{' | '}</Caption>
        <Button
          onPress={() => openLinkInNewTab(PRIVACY_POLICY_URL)}
          mode="text"
          compact={true}
          labelStyle={[styles.linkLikeButton, styles.captionButton]}
        >
          {PRIVACY_POLICY}
        </Button>
      </View>
      <View style={[styles.inlineTextAndButtons, { paddingTop: UNIT_MULTIPLE / 2 }]}>
        <Caption style={[styles.caption, { color: colors.text }]}>
          {TITLE} {VERSION}
          {' | '}
        </Caption>
        <Caption style={[styles.caption, { color: colors.text }]}>{CREATED_BY} </Caption>
        <Button
          onPress={() => openLinkInNewTab(PERSONAL_SITE)}
          mode="text"
          compact={true}
          labelStyle={[styles.linkLikeButton, styles.captionButton]}
        >
          {CHRISTIAN_MEMIJE}
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { padding: UNIT_MULTIPLE },
  inlineTextAndButtons: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  badgesWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  badgeImage: {
    width: 136,
    height: 40.256,
    resizeMode: 'contain',
  },
  linkLikeButton: {
    marginRight: 0,
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  badgesSpacer: { width: UNIT_MULTIPLE },

  subHeading: { lineHeight: subheadingFontSize * 1.25 },
  subHeadingButton: { fontSize: subheadingFontSize },
  caption: { lineHeight: captionFontSize * 1.25, textAlign: 'center' },
  captionButton: { fontSize: captionFontSize },
});
