import React, { useState, useMemo, useEffect } from 'react';
import { View, SafeAreaView, ScrollView, Platform } from 'react-native';
import { Provider as PaperProvider, Snackbar } from 'react-native-paper';
import { StyleSheet, Clipboard } from 'react-native';
import { AppLoading } from 'expo';
import { useFonts } from '@use-expo/font';
import { APP_THEME, APP_FONTS, UNIT_MULTIPLE, ACCENT_COLOR } from './app/utils/theme';
import { IPad, Pad } from './app/components/Pad';
import { Dimensions } from 'react-native';
import { Header } from './app/components/Header';
import { Footer } from './app/components/Footer';
import Fuse from 'fuse.js';
import { allPads, trapPads, corridoPads } from './app/utils/pads';
import { Sound } from 'expo-av/build/Audio';
import { Audio, AVPlaybackStatus } from 'expo-av';
import { AUDIO_MAP } from './AudioMap';
import * as Sharing from 'expo-sharing';
import { setStatusBarStyle } from 'expo-status-bar';
import { EGenreMode } from './app/utils/models';
import { SHARE_AD_LIB, COPIED } from './app/utils/strings';
import * as FileSystem from 'expo-file-system';
import { Asset } from 'expo-asset';
import * as Linking from 'expo-linking';
import { BACKGROUND_COLOR } from './app/utils/constants';

function calculateCardWidth(width: number): number {
  const updatedWidth = width - 15;
  const minPadWidth: number = 320 / 2;
  const cardsPerRow: number = Math.max(Math.floor(updatedWidth / minPadWidth), 1);
  const newCardWidth: number = Math.floor(updatedWidth / cardsPerRow);
  return newCardWidth;
}

setStatusBarStyle('light');

export default function App() {
  Sharing.isAvailableAsync().then((val: boolean) => {
    setCanShare(val && Platform.OS === 'android');
  });

  const [canShare, setCanShare] = useState(false);
  const [snackbarIsVisible, setSnackbarIsVisible] = useState(false);
  const [gridItemWidth, setGridItemWidth] = useState(calculateCardWidth(Dimensions.get('window').width));

  const [padToPlay, setPadToPlay] = useState<IPad>();
  const [playRequested, setPlayRequested] = useState<number>();
  const [soundObject, setSoundObject] = useState<Sound>();
  useEffect(() => {
    if (padToPlay) {
      if (soundObject) {
        soundObject.stopAsync();
      }
      const newSoundObject: Sound = new Audio.Sound();
      setSoundObject(newSoundObject);
      newSoundObject.setOnPlaybackStatusUpdate((status: AVPlaybackStatus) => {
        if (status.isLoaded) {
          if (status.didJustFinish) {
            setSoundObject(undefined);
            setPadToPlay(undefined);
          }
        }
      });
      newSoundObject.loadAsync(AUDIO_MAP[padToPlay.path]).then(() => newSoundObject.playAsync());
    }
  }, [playRequested]);

  const [padToShare, setPadToShare] = useState<IPad>();
  const [shareRequested, setShareRequested] = useState<number>();
  useEffect(() => {
    if (padToShare) {
      const file: Asset = Asset.fromModule(AUDIO_MAP[padToShare.path]);
      file.downloadAsync().then(() => {
        if (canShare) {
          const cacheFilePath: string = `${FileSystem.cacheDirectory as string}${file.name}.${file.type}`;
          FileSystem.copyAsync({
            from: file.localUri as string,
            to: cacheFilePath,
          }).then(() => {
            Sharing.shareAsync(cacheFilePath, {
              dialogTitle: SHARE_AD_LIB(padToShare.name, padToShare.artist),
              mimeType: 'audio/mp3',
              UTI: 'public.mp3',
            });
          });
        } else {
          const fullPath = Linking.makeUrl(file.localUri as string);
          Clipboard.setString(fullPath);
          setSnackbarIsVisible(false);
          setTimeout(() => {
            setSnackbarIsVisible(true);
          }, 100);
        }
      });
    }
  }, [shareRequested]);

  const [inSearchMode, setInSearchMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [genreMode, setGenreMode] = useState<EGenreMode>(EGenreMode.TrapYCorridos);

  const filteredPads = useMemo(() => {
    let pads: IPad[] = allPads;
    if (genreMode === EGenreMode.Trap) {
      pads = trapPads;
    } else if (genreMode === EGenreMode.Corridos) {
      pads = corridoPads;
    }
    if (inSearchMode && searchTerm) {
      const fuse = new Fuse(pads, {
        keys: ['name', 'artist'],
        threshold: 0.4,
      });
      pads = fuse.search(searchTerm).map(({ item }: Fuse.FuseResult<IPad>) => item);
      return pads;
    }
    return pads;
  }, [inSearchMode, searchTerm, genreMode]);

  const [scrollViewRef, setScrollViewRef] = useState<ScrollView>();
  useEffect(() => {
    if (scrollViewRef) {
      scrollViewRef.scrollTo({ y: 0, animated: false });
    }
  }, [inSearchMode, searchTerm, genreMode, scrollViewRef]);

  const [isLoaded] = useFonts(APP_FONTS);
  if (!isLoaded) {
    return <AppLoading />;
  }

  return (
    <PaperProvider theme={APP_THEME}>
      <SafeAreaView
        style={styles.container}
        onLayout={(event) => {
          const newCardWidth: number = calculateCardWidth(event.nativeEvent.layout.width);
          setGridItemWidth(newCardWidth);
        }}
      >
        <Header
          inSearchMode={inSearchMode}
          onSetSearchMode={(searchMode: boolean) => {
            setInSearchMode(searchMode);
            setSearchTerm('');
          }}
          searchTerm={searchTerm}
          onSetSearchTerm={(term: string) => {
            setSearchTerm(term);
          }}
          genreMode={genreMode}
          onSetGenreMode={(genre: EGenreMode) => setGenreMode(genre)}
        />
        <ScrollView style={styles.scrollView} ref={(ref: ScrollView) => setScrollViewRef(ref)}>
          <>
            <View style={styles.grid}>
              {filteredPads.map((pad: IPad) => (
                <View
                  key={pad.path}
                  style={[
                    {
                      width: gridItemWidth,
                      height: gridItemWidth,
                    },
                    styles.gridItem,
                  ]}
                >
                  <Pad
                    pad={pad}
                    onShare={() => {
                      setPadToShare(pad);
                      setShareRequested(Date.now());
                    }}
                    onPlay={() => {
                      setPadToPlay(pad);
                      setPlayRequested(Date.now());
                    }}
                    isPlaying={!!padToPlay && padToPlay.path === pad.path}
                    canShare={canShare}
                  ></Pad>
                </View>
              ))}
            </View>
            <Footer />
          </>
        </ScrollView>
        <Snackbar
          visible={snackbarIsVisible}
          onDismiss={() => setSnackbarIsVisible(false)}
          style={styles.snackbar}
          duration={3000}
        >
          {COPIED}
        </Snackbar>
      </SafeAreaView>
    </PaperProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: BACKGROUND_COLOR,
  },
  scrollView: {
    flex: 1,
  },
  grid: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  gridItem: { padding: UNIT_MULTIPLE / 2 },
  snackbar: { backgroundColor: ACCENT_COLOR },
});
