import { SITE_URL, PRIVACY_POLICY_FILE_NAME, CREDITS_FILE_NAME } from './constants';

export function SHARE_AD_LIB(adLibName: string, artistName: string): string {
  return `Comparte ${adLibName} de ${artistName}`;
}
export const SHARE: string = 'Comparte';
export const COPY_LINK: string = 'Copiar enlace al portapapeles';
export const COPIED: string = 'Enlace copiado al portapapeles!';
export const PERSONAL_SITE: string = 'https://trapnstudio.com';
export const CHRISTIAN_MEMIJE: string = 'TRAPNSTUDIO';
export const CREATED_BY: string = 'Creado por';
export const MISSING_ADLIB: string = 'Falta una frase?';
export const REQUEST_IT: string = 'Solicita una nueva!';
export const CREDITS: string = 'Creditos';
export const PRIVACY_POLICY: string = 'Politica de privacidad';
export const PRIVACY_POLICY_URL: string = `${SITE_URL}/${PRIVACY_POLICY_FILE_NAME}`;
export const CREDITS_URL: string = `${SITE_URL}/${CREDITS_FILE_NAME}`;
export const COMMENTS_SUGGESTIONS: string = 'Comentarios? Sugerencias?';
export const INSTAGRAM: string = 'Instagram';
export const EXIT_SEARCH: string = 'Salir del modo de busqueda';
export const ENTER_SEARCH: string = 'Ingresar al modo de busqueda';
export const CLEAR_SEARCH: string = 'Borrar';
export const SEARCH: string = 'Buscar';
export const GET_ON_GOOGLE_STORE: string = 'Descargar la aplicacion en Google Play';
export const GET_ON_APPLE_STORE: string = 'Descargar la aplicacion en App Store';
export const GOOGLE_STORE_LINK: string =
  'https://play.google.com/store/apps/details?id=com.christianmemije.trapcorridossoundboard';
export const APPLE_STORE_LINK: string = 'https://apps.apple.com';
